<template>
  <div class="home-index p20">
    <div class="bg p20 mb20">
      <div class="title-btn-block">
            <div class="title-block">{{ $t("message.user_profile") }}</div>
            <div>
                <router-link :to="{ name: 'Home' }">
                    <div class="my-btn cr1 el-button">
                        <i class="el-icon-d-arrow-left"></i> {{$t('message.back')}}
                    </div>
                </router-link>
            </div>
        </div>
        <el-row :gutter="20" >
          <el-col  :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <div  class="card-table p-4 mb-5 text-center">
              <div class="demo-image__preview">
                <div class="img-user">
                  <img
                    :src="
                    profile.image
                        ? baseUrl + profile.image.path
                        : '/img/avatar.jpeg'
                    "
                  />
                </div>
                <!-- <el-image
                  class="my-c-avatar"
                  style="width: 100px; height: 100px"
                  :src="
                        profile.image
                          ? baseUrl + profile.image.path
                          : '/img/avatar.jpeg'
                      "
                
                >
                </el-image> -->
                
              <div class="user_info">
                <h2 class="user_name">{{ profile.name }} {{ profile.last_name }} {{ profile.middle_name }}</h2>
                <h4 class="position">{{ profile.position?profile.position.name:'' }}</h4>
                <h5 class="otdel">{{ profile.department?profile.department.name:''}}</h5>
              </div>
              </div>
            </div>
            <div  class="card-table p-4 mb-5">
              <ul class="info_list">
                <li class="info_item">
                  <span class="item_title">{{ $t("message.company") }}</span>
                  <span class="item_value">
                      {{profile.branch&&profile.branch.company?(profile.branch.company.name):''}}
                  </span>
                </li>
                <li class="info_item">
                  <span class="item_title">{{ $t("message.branch_a") }}</span>
                  <span class="item_value">
                      {{profile.branch?(profile.branch.name):''}}
                  </span>
                </li>
                <li class="info_item">
                  <span class="item_title">{{ $t("message.department") }}</span>
                  <span class="item_value">
                      {{profile.department?(profile.department.name):''}}
                  </span>
                </li>
                <li class="info_item">
                  <span class="item_title">{{ $t("message.position") }}</span>
                  <span class="item_value">
                      {{profile.position?(profile.position.name):''}}
                  </span>
                </li>
                <!-- <li class="info_item">
                  <span class="item_title">{{ $t("message.limit_work_time") }}</span>
                  <span class="item_value">{{profile.work_mounth_plane.limit.current_limit}} / {{profile.work_mounth_plane.limit.mounth_limit}}</span>
                </li> -->
                <!-- <li class="info_item">
                  <span class="item_title">{{ $t("message.actual_working_time") }}</span>
                  <span class="item_value">
                        <b style="color: #67c23a" v-if="profile.work_mounth_plane.work_time>profile.work_mounth_plane.limit.current_limit">  {{profile.work_mounth_plane.work_time}} час</b>
                        <b style="color: #ff8f8f" v-else>  {{profile.work_mounth_plane.work_time}} час</b>
                  </span>
                </li> -->
                <!-- <li class="info_item">
                  <span class="item_title">{{ $t("message.salary") }}</span>
                  <span class="item_value">{{Number(profile.activeSalaries).toLocaleString()}} сум</span>
                </li> -->
              </ul>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18" >
           
            <el-row :gutter="20">
              <el-col :span="24">
                <div  class="card-table p-4 mb-5">
                  <h3 class="block_title" >{{ $t("message.user_information") }}</h3>
                  <ul class="info_list">
                    <li class="info_item">
                      <span class="item_title">{{ $t("message.manager") }}</span>
                      <span class="item_value">
                          {{profile.manager?(profile.manager.name+' '+profile.manager.last_name+' '+profile.manager.middle_name ):''}}
                      </span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">{{ $t("message.limit_work_time") }}</span>
                      <span class="item_value">
                            {{Math.floor(profile.work_mounth_plane.limit.current_limit)}} {{$t('message.hour')}} {{Math.round((profile.work_mounth_plane.limit.current_limit - Math.floor(profile.work_mounth_plane.limit.current_limit))*60)}} {{$t('message.minut')}}
                          / {{Math.floor(profile.work_mounth_plane.limit.mounth_limit)}} {{$t('message.hour')}} {{Math.round((profile.work_mounth_plane.limit.mounth_limit - Math.floor(profile.work_mounth_plane.limit.mounth_limit))*60)}} {{$t('message.minut')}}
                          <!-- {{staff.work_mounth_plane.limit.mounth_limit}} -->                        
                        <!-- {{profile.work_mounth_plane.limit.current_limit}} / {{profile.work_mounth_plane.limit.mounth_limit}} -->
                      </span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">{{ $t("message.actual_working_time") }}</span>
                      <span class="item_value">
                        <b style="color: #67c23a" v-if="profile.work_mounth_plane.work_time>profile.work_mounth_plane.limit.current_limit"> {{Math.floor(profile.work_mounth_plane.work_time)}} {{$t('message.hour')}} {{Math.round((profile.work_mounth_plane.work_time - Math.floor(profile.work_mounth_plane.work_time))*60)}} {{$t('message.minut')}} </b>
                        <b style="color: red" v-else-if="(profile.work_mounth_plane.work_time>0)"> {{Math.floor(profile.work_mounth_plane.work_time)}} {{$t('message.hour')}} {{Math.round((profile.work_mounth_plane.work_time - Math.floor(profile.work_mounth_plane.work_time))*60)}} {{$t('message.minut')}} </b>
                        <b style="color: red" v-else> {{profile.work_mounth_plane.work_time}} {{$t('message.hour')}} </b>
                      </span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">{{ $t("message.salary") }}</span>
                      <span class="item_value">{{Number(profile.activeSalaries).toLocaleString()}} сум</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">{{ $t("message.bonuse") }}</span>
                      <span class="item_value" style="color:#22c443">{{Number(profile.bonuse).toLocaleString()}} сум</span>
                    </li>
                     <li class="info_item">
                      <span class="item_title">{{ $t("message.penalties") }}</span>
                      <span class="item_value" style="color:#ff8f8f">{{Number(profile.penalties).toLocaleString()}} сум</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">{{ $t("message.accept_vacation_managment") }}</span>
                      <span class="item_value" style="color:#22c443">{{profile.last_vacation_managment?formatMonthDate(profile.last_vacation_managment.month):$t("message.not_attached")}} </span>
                    </li>
                  </ul>
                   <h3 class="block_title">{{ $t("message.requirement") }}</h3>
                    <ul class="info_list">
                      <li class="info_item">
                        <span class="item_title">{{ $t("message.name") }}</span>
                        <span class="item_title">{{ $t("message.comment") }}</span>
                      </li>

                      <li class="info_item" v-for="requirement in profile.client_requirements" :key="requirement.id">
                        <span class="item_title">{{requirement.requirements[0]&&requirement.requirements[0].name?requirement.requirements[0].name:''}}</span>
                        <span class="item_value" v-html="requirement.requirements[0]&&requirement.requirements[0].description?requirement.requirements[0].description:''">{{requirement.requirements[0]&&requirement.requirements[0].description?requirement.requirements[0].description:''}}</span>
                      </li>
                    </ul>
                </div>
              </el-col>
              <!-- <el-col :span="12">
                <div  class="card-table p-4 mb-5">
                  <ul class="info_list">
                    <li class="info_item">
                      <span class="item_title">Норма работы (час)</span>
                      <span class="item_value">Fullname</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">Зарплата</span>
                      <span class="item_value">Fullname</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">Руководитель</span>
                      <span class="item_value">Fullname</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">Штрафы</span>
                      <span class="item_value">Fullname</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">Требования</span>
                      <span class="item_value">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod exercitationem provident similique quia sint aliquid nisi repellendus neque amet porro repellat, et minus unde eligendi impedit! Sapiente dicta nisi dolorem.</span>
                    </li>
                  </ul>
                </div>
              </el-col>
              <el-col :span="12">
                <div  class="card-table p-4 mb-3">
                  <ul class="info_list">
                    <li class="info_item">
                      <span class="item_title">Норма работы (час)</span>
                      <span class="item_value">Fullname</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">Зарплата</span>
                      <span class="item_value">Fullname</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">Руководитель</span>
                      <span class="item_value">Fullname</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">Штрафы</span>
                      <span class="item_value">Fullname</span>
                    </li>
                    <li class="info_item">
                      <span class="item_title">Требования</span>
                      <span class="item_value">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod exercitationem provident similique quia sint aliquid nisi repellendus neque amet porro repellat, et minus unde eligendi impedit! Sapiente dicta nisi dolorem.</span>
                    </li>
                  </ul>
                </div>
              </el-col> -->
            </el-row>
          </el-col>
        </el-row>
    </div>

     
  </div>
</template>

<script>
import { getItem } from "@/utils/storage";
import moment from 'moment';
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {},
      baseUrl: process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 4),
      staffId: null,
      // list: [],
    };
  },

  mounted() {
    if (getItem("userId") == null) {
      let route = this.$router.push({
        name: "login",
       
      });
      window.open(route.href, "_self");
    }

    this.getUser();
    this.staffId = getItem("userId");
  },
   computed:{
    ...mapGetters({
      profile:"profile/profile",
    })
  },
  methods: {
    ...mapActions({
      attendance: "attendanceReport/index",
      updateList: "profile/index",
    }),
    getUser() {      
      this.updateList(getItem('userId')).then((res) => {
         if (res.status==200) { 
        }
        else{
           let route = this.$router.push({
              name: "login",
            });
        }
      });
    },
    formatMonthDate(value){
      if (value) {
        return moment((value)).lang("ru").format('MMMM YYYY')
      }
    }
  },
};
</script>
<style lang="scss">
.card-block-itme{
  display: flex;
  flex-direction: column;
  font-size: 24px;
  .for-icon{
    font-size: 80px;
  }
}
.card-table{
  background-clip: border-box;
    border: 1px solid #3b464f56;
    border-radius: 10px;
    margin-bottom: 1.2rem;
    box-shadow: 0 4px 24px 0 #1e242a9e;
    padding: 1rem ;
   
    background:#161d31;
    .my-c-avatar {
    width: 100% !important;
    height: auto !important;
        img{
              width: 13vw;
              height: 13vw;
              border-radius: 10%;
              border: 1px solid #cacaca;
              -o-object-fit: cover;
              object-fit: cover;
          }
        }
    .user_info{
      .user_name{
        margin: 5px auto;
        color: #cacaca;
        font-size: 20px;
      }
      .position{
        margin: 10px auto;
        color: #0d67c9;
      }
      .otdel{
        margin: 10px auto;
        color: #cacaca;
      }
    }
}
.text-center{
 text-align: center;
}

.info_list{
  padding: 0;
  margin: 0;
  list-style: none;

  .info_item{
    padding: 10px 15px;
    border-bottom: 1px solid #64646480;
    display: flex;
    .item_title{
      font-size: 17px;
      width: 30%;
      font-weight: 700;
      color: #eaeaeaf9;;
    }
    .item_value{
      font-size: 17px;
      color: #dbd9d9ef;;
        width: 70%;
    }
    &:last-child{
      border: none;
    }
  }

}
.block_title{
   color: #dbd9d9ef;
   margin: 15px 0;
   font-size: 24px;
}
@media only screen and (max-width: 900px) {
  .info_list .info_item {
    .item_title {
      width: 100% !important;
    }
    .item_value{
      width: 100% !important;
    }
  }
  .card-table {
      .info_item{
        display: flex;
        flex-direction: column;
      }
      
  }

}

</style>



